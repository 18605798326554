import anime from "animejs";
import { appUtils, configs } from "..";
import { AppBase } from "../appBase";
import { TypeDataCategory, TypeDataProduct, TypeDirFactor } from "../data";
import Loader from "../loader";
import Parallax from "./parallax";

export default class ProductBase {

    protected _data: TypeDataProduct;
    protected _catData: TypeDataCategory;
    protected _cont: HTMLElement;
    protected _contMask = { value: 0 };
    protected _animating: boolean = true;
    //protected _imgFeature: HTMLElement;
    protected _boxes: NodeListOf<HTMLElement>;
    protected _bgIngredients: HTMLElement;
    protected _parallax: Parallax;

    get data() { return this._data; }
    get catData() { return this._catData; }
    get cont() { return this._cont; }
    get animating() { return this._animating; }

    constructor() {
    }

    protected _setBackground(isDetail: boolean = false) {
        // if (isDetail) {
        const bgImgPath = this._data.bg_detail_img ? `${this._data.categoryId}/${this._data.id}` : this._data.categoryId,
            bgStr = this._data.bg_detail_str || this._catData.bg_detail_str;

        if (bgStr) {
            this._cont.style.background = bgStr;
            // this._cont.style.backgroundImage = "url(s_images/noise.png)";
        }
        else if (bgImgPath) {
            this._cont.style.backgroundImage = `url(${configs.productsImagePath + bgImgPath}/bg${this._data.categoryId == "special" ? "" : "-detail"}.jpg)`;
        }
        /* } else {
            const bgPath = (this._data.bg_home ? `${this._data.categoryId}/${this._data.id}` : this._data.categoryId);
            this._cont.style.backgroundImage = `url(${configs.productsImagePath + bgPath}/bg.jpg)`;
        } */
    }
    /*
    protected _setImgFeature() {
        if (this._data.feature_image_width > 0)
            this._imgFeature.style.setProperty("--width", this._data.feature_image_width + "px");
        else
            this._imgFeature.style.display = "none";
    }
    */
    protected _fillBgIngredients(needToLoad: boolean = false) {
        this._data.bg_ingredients.forEach(item => {
            const img = document.createElement("img");
            img.src = configs.productsImagePath + `${this._data.categoryId}/${this._data.id}/${item.image}.png`;
            img.style.width = (item.width / configs.defWidth * 100) + "%";
            img.style.left = (item.x / configs.defWidth * 100) + "%";
            img.style.top = (item.y / configs.defHeight * 100) + "%";
            img.style.zIndex = img.dataset["layer"] = (item.layer || "").toString();
            if (needToLoad)
                img.dataset["load"] = "1";
            // img.style.transform = "translate(-50%, -50%)";
            this._bgIngredients.appendChild(img);
        });
    }

    protected _addParallax(app: AppBase) {
        this._parallax = new Parallax(app, this._boxes, this._bgIngredients.querySelectorAll(":scope>img"));
    }

    addImagesToLoad(loader: Loader) {
        const addItem = item => {
            if (item.tagName == "IMG")
                loader.add((item as HTMLImageElement).src);

            const bgMatch = (item.style.background || item.style.backgroundImage || "").match(/url\(([\s\S]*?)\)/);
            if (bgMatch && bgMatch.length > 1)
                loader.add(bgMatch[1].replace(/\"/g, ""));
        };

        addItem(this._cont);
        this._cont.querySelectorAll<HTMLElement>("[data-load]").forEach(addItem);
    }

    show(dirFactor: TypeDirFactor) {
        if (!this._cont)
            return;

        this._animating = true;
        this._cont.style.display = "";
        this._cont.style.zIndex = "1";

        if (this._parallax)
            this._parallax.setToNewPos();

        if (dirFactor == 0) {
            this._cont.style.clipPath = 'unset';
            this._animBox(true, 1, () => this._onShowDone());
            return;
        }

        this._cont.style.clipPath = 'polygon(0 0, 0 0, 0 0, 0 0)';

        this._animBox(true, dirFactor);
        anime({
            targets: this._cont.querySelectorAll("[data-anim]"),
            translateX: [dirFactor * 2 + "vw", 0], opacity: [0, 1],
            delay: anime.stagger(40, { start: 200 }),
            duration: configs.animDurProductChange * .75, easing: 'easeOutSine',
        });
        
        anime({
            targets: [this._bgIngredients.children],
            opacity: [0, 1],
            translateX: [() => dirFactor * appUtils.rand({ min: 2, max: 5 }) + "vw", 0],
            // rotate: [() => appUtils.rand({ min: -30, max: 30 }), 0],
            duration: configs.animDurProductChange * .9, easing: 'easeOutSine',
            delay: anime.stagger(75, { from: "center" }),
            complete: () => this._onShowDone()
        });
        
        anime({
            targets: this._contMask, value: dirFactor < 0 ? [0, -100] : [100, 0],
            duration: configs.animDurProductChange * .75, easing: 'easeInOutSine',
            update: () => {
                this._cont.style.clipPath =
                    this._contMask.value > 0 ?
                        `polygon(${this._contMask.value}% 0, 100% 0, 100% 100%, ${this._contMask.value}% 100%)` :
                        `polygon(0 0, ${-this._contMask.value}% 0, ${-this._contMask.value}% 100%, 0 100%)`;
            },
            complete: () => this._cont.style.clipPath = "unset"
        });
    }

    protected _onShowDone() {
        this._animating = false;
        if (this._parallax) this._parallax.enabled = true;
    }

    hide(dirFactor: TypeDirFactor) {
        if (!this._cont)
            return;

        this._animating = true;
        if (this._parallax) this._parallax.enabled = false;

        if (dirFactor == 0) {
            this._animBox(false, -1, () => this._onHideDone());
            return;
        }

        this._cont.style.zIndex = "0";

        this._animBox(false, dirFactor);
        anime({
            targets: this._cont.querySelectorAll("[data-anim]"),
            translateX: dirFactor * -2 + "vw", opacity: 0,
            delay: anime.stagger(40),
            duration: configs.animDurProductChange * .9, easing: 'easeInOutSine',
        });
        
        anime({
            targets: [this._bgIngredients.children],
            opacity: 0,
            translateX: () => dirFactor * appUtils.rand({ min: -4, max: -8 }) + "vw",
            // rotate: () => appUtils.rand({ min: -30, max: 30 }),
            delay: anime.stagger(75, { from: "center" }),
            duration: configs.animDurProductChange * .9, easing: 'easeInOutSine',
            complete: () => this._onHideDone()
        });
        
    }

    protected _onHideDone() {
        this._animating = false;
        this._cont.style.clipPath = "unset";
        this._cont.style.display = "none";
    }

    protected _onLangChange() {

    }

    protected _animBox(show: boolean, dirFactor: TypeDirFactor, onComplete: Function = null) {
        if (show)
            anime({
                targets: this._boxes,
                translateX: [dirFactor * 15 + "%", 0],
                rotateY: [0, this._parallax ? this._parallax.boxRotation : configs.boxesRotateYDeg],
                duration: configs.animDurProductChange, easing: 'easeInOutSine',
                delay: 100, complete: () => onComplete && onComplete()
            });
        else
            anime({
                targets: this._boxes,
                translateX: dirFactor * -15 + "%", rotateY: 0,
                duration: configs.animDurProductChange, easing: 'easeInOutSine',
                complete: () => onComplete && onComplete()
            });
    }
}