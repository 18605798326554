import { appUtils, configs, IPoint, isMobile } from "..";
import { AppBase } from "../appBase";

export default class Parallax {

    private _events: { [name: string]: (this: HTMLElement | Window, ev: MouseEvent) => void } = {};
    private _bgItems: NodeListOf<HTMLElement>;
    private _enabled: boolean;
    private _newPos: IPoint = { x: 0, y: 0 };
    private _currentPos: IPoint = { x: 0, y: 0 };
    private _boxes: NodeListOf<HTMLElement>;
    private _app: AppBase;

    get boxRotation() { return (configs.boxesRotateYDeg * (1 + this._currentPos.x * .5)); }

    get enabled() { return this._enabled; }
    set enabled(v: boolean) {
        const prevVal = this._enabled;
        this._enabled = v;
        if (!prevVal && v) {
            this._onMouseMove();
            this._update();
        }
    }

    constructor(app: AppBase, boxes: NodeListOf<HTMLElement>, bgItems: NodeListOf<HTMLElement>) {
        this._app = app;
        this._boxes = boxes;
        this._bgItems = bgItems;
        this._bgItems.forEach(item => {
            item.dataset["left"] = item.style.left;
            item.dataset["top"] = item.style.top;
        });
        this._setEvents();
    }

    setToNewPos() {
        if (isMobile)
            return;

        this._onMouseMove();
        this._currentPos.x = this._newPos.x;
        this._currentPos.y = this._newPos.y;
        this._update(true);
    }

    private _setEvents(remove: boolean = false) {
        if (remove) {
            window.removeEventListener("mousemove", this._events["mousemove"]);
        } else if (!isMobile) {
            window.addEventListener("mousemove", this._events["mousemove"] = this._onMouseMove.bind(this));
        }
    }

    private _onMouseMove() {
        const wHalf = window.innerWidth / 2,
            hHalf = window.innerHeight / 2,
            x = this._app.mousePos.x < 0 ? wHalf : this._app.mousePos.x,
            y = this._app.mousePos.y < 0 ? hHalf : this._app.mousePos.y;
        this._newPos.x = (wHalf - x) / wHalf;
        this._newPos.y = (hHalf - y) / hHalf;
    }

    private _update(force: boolean = false) {
        if ((!force && !this._enabled) || isMobile)
            return;

        this._currentPos.x = appUtils.lerp(this._currentPos.x, this._newPos.x, .05);
        this._currentPos.y = appUtils.lerp(this._currentPos.y, this._newPos.y, .05);

        this._boxes.forEach(item => {
            item.style.transform = item.style.transform.replace(/rotateY\(.*?\)/, "rotateY(" + this.boxRotation + "deg)");
        });

        this._bgItems.forEach(item => {
            const layer = parseFloat(item.dataset["layer"]) || 0,
                left = parseFloat(item.dataset["left"]),
                top = parseFloat(item.dataset["top"]);

            item.style.left = left + this._currentPos.x * ((layer + 1) * 1.25) + "%";
            item.style.top = top + this._currentPos.y * ((layer + 1) * 1.25) + "%";
        });

        if (!force)
            window.requestAnimationFrame(() => this._update());
    }
}